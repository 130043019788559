@import "Core/styles/index.scss";

.container {
  padding         : 20px;
  border-radius   : 15px;
  margin-bottom   : 10px;
  cursor          : pointer;
  transition      : all .2s;
  background-color: white;
  position        : relative;
  border          : solid 1px rgba($color-mischka, 0.5);
  @include flex(center);

  &:hover {
    border-color: $color-downy;
  }

  .percent {
    flex-shrink : 0;
    margin-right: 15px;
    font-size   : 100px;
  }

  .content {
    padding-right: 30px;

    h4 {
      font-weight  : 600;
      font-size    : 16px;
      margin-bottom: 10px;
    }

    h5 {
      font-size  : 14px;
      line-height: 1.41;
      font-weight: normal;
      color      : $color-tundora;
    }
  }

  .icon {
    color    : $color-codGray;
    transform: translateY(-50%);
    @include absolute(50%, 20px);
  }
}