@import "Core/styles/index.scss";

.container {
  margin-bottom: 10px;
  cursor       : pointer;
  position     : relative;

  label {
    font-weight: 500;
    font-size  : 14px;
    @include absolute(10px, null, null, 15px);
  }

  .content {
    width        : 100%;
    font-size    : 16px;
    border-radius: 15px;
    display      : block;
    transition   : all .2s;
    padding      : 36px 15px 10px 15px;
    color        : rgba($color-codGray, 0.4);
    border       : 2px solid rgba($color-mischka, 0.5);

    &:hover {
      border-color: rgba($color-downy, 0.5);
    }

    &.filled {
      font-weight: 500;
      color      : $color-codGray;
    }

    &.filled,
    &:focus {
      border-color: $color-downy;
    }

    &:disabled {
      background-color: white;
    }
  }

  .menu {
    z-index         : 3;
    transform-origin: top;
    overflow-y      : auto;
    border-radius   : 15px;
    max-height      : 400px;
    transition      : all .2s;
    background-color: white;
    transform       : scaleY(0);
    box-shadow      : rgba($color-tundora, 0.1) 0px 8px 24px;
    @include absolute(100%, 0, null, 0);

    &.visible {
      transform: scaleY(1);
    }

    .item {
      transition: all .2s;
      padding   : 8px 16px;

      &:hover {
        background-color: rgba($color-downy, 0.1);
      }

      &.active {
        background-color: rgba($color-downy, 0.2);
      }
    }
  }
}