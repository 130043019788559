@import "Core/styles/index.scss";

.container {

  .content {
    padding   : 120px 0;
    text-align: justify;
    @include content(900px, 100%);

    h1 {
      font-size    : 36px;
      margin-bottom: 20px;
      text-align   : center;
    }

    h3 {
      font-size    : 18px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 10px;
    }

    a {
      font-weight    : 500;
      text-decoration: underline;
    }
  }
}