@import "Core/styles/index.scss";

.container {
  white-space: nowrap;

  span {
    font-weight: normal;
    cursor     : pointer;
    transition : all .2s;
    @include responsive(margin, 0 20px, 0 12px);
    @include responsive(font-size, 16px, 14px);

    &:hover {
      color: $color-downy;
    }
  }
}