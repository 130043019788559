@import "Core/styles/index.scss";

.container {
  background-color: $color-iceBlue;

  .content {
    display: flex;
    @include responsive(flex-direction, null, null, column);
    @include responsive(padding, 40px 0, 30px 0, 20px 0);
    @include content(1180px, 904px, 100%);

    .left {
      @include responsive(margin-bottom, null, null, 30px);
      @include responsive(text-align, null, null, center);

      .logo {
        @include responsive(margin-bottom, 30px, 20px, 16px);
        @include responsive(height, 30px, 20px, 20px);
      }

      p {
        font-weight: 500;
        @include responsive(letter-spacing, 0.57px, 0.5px, 0.5px);
        @include responsive(margin-bottom, 10px, 10px, 16px);
        @include responsive(font-size, 16px, 14px, 14px);
      }

      .appStore {
        margin-right: 10px;
        @include responsive(height, 38px, 27px, 36px);
      }

      .googlePlay {
        @include responsive(height, 38px, 27px, 36px);
      }
    }

    .right {
      display: flex;
      @include responsive(flex-direction, null, null, column);
      @include responsive(align-items, null, null, center);
      @include responsive(margin-left, auto, auto, 0);

      nav {
        @include responsive(margin-bottom, null, null, 30px);
        @include responsive(text-align, null, null, center);
        @include responsive(width, 250px, 140px);
        @include flex(null, null, column);

        label {
          letter-spacing: 1px;
          font-weight   : bold;
          text-transform: uppercase;
          @include responsive(margin-bottom, 20px, 15px, 15px);
          @include responsive(font-size, 14px, 14px, 14px);
          @include responsive(opacity, 0.6, 0.6, 1);
        }

        span {
          letter-spacing: 1px;
          cursor        : pointer;
          transition    : all .2s;
          @include responsive(margin-bottom, 15px, 15px, 10px);
          @include responsive(font-size, 14px, 14px, 14px);

          &:hover {
            color: $color-downy;
          }
        }
      }
    }
  }

  .copyright {
    font-weight: 500;
    text-align : center;
    color      : rgba($color-codGray, 0.6);
    border-top : 1px solid $color-paleLilac;
    @include responsive(letter-spacing, 0.5px, 0.41px, 0.41);
    @include responsive(padding, 30px 0, 20px 0, 45px 0);
    @include responsive(font-size, 16px, 13px, 13px);
  }
}