@import "Core/styles/index.scss";

.container {
  opacity       : 0;
  height        : 100%;
  z-index       : 8800;
  pointer-events: none;
  text-align    : center;
  transition    : opacity .2s ease;
  @include fixed(0, 0, null, 0);

  .close {
    padding      : 0;
    z-index      : 2;
    width        : 44px;
    height       : 44px;
    font-size    : 18px;
    border-radius: 22px;
    @include absolute(40px, 40px);
    @include flex(center, center);

    svg {
      margin-right: 0;
    }
  }

  &::before {
    width         : 0;
    content       : "";
    height        : 100%;
    vertical-align: middle;
    display       : inline-block;
  }

  .content {
    z-index         : 2;
    padding         : 24px;
    max-width       : 100%;
    text-align      : left;
    border-radius   : 20px;
    width           : 530px;
    vertical-align  : middle;
    transform       : scale(0);
    position        : relative;
    display         : inline-block;
    transition      : all .2s ease;
    background-color: $color-athensGray;
  }

  &.drawer {
    text-align: right;

    .content {
      border-radius   : 0;
      height          : 100%;
      padding         : 40px;
      overflow-y      : auto;
      transform-origin: right;
      transform       : scaleX(0);
    }
  }

  &.opened {
    opacity       : 1;
    pointer-events: all;

    .content {
      transform: scale(1);

      &.drawer {
        transform: scaleX(1);
      }
    }
  }

  .filter {
    background-color: rgba($color-codGray, 0.5);
    @include absolute(0, 0, 0, 0);
  }
}