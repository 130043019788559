@import "Core/styles/index.scss";

.container {
  background-color: $color-downy;
  @include flex(center, center);
  @include fixed(0, 0, 0, 0);

  img {
    height   : 60px;
    max-width: calc(100% - 80px);
  }
}