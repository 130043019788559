@import "Core/styles/index.scss";

.container {
  display      : flex;
  margin-bottom: 15px;

  .icon {
    width           : 28px;
    height          : 28px;
    margin-right    : 10px;
    border-radius   : 15px;
    vertical-align  : middle;
    cursor          : pointer;
    color           : transparent;
    display         : inline-block;
    background-color: $color-mischka;
    border          : 2px solid $color-mischka;
  }

  span {
    padding-top: 5px;
    font-size  : 14px;
  }

  &.checked {

    .icon {
      position        : relative;
      border-color    : $color-downy;
      background-color: $color-downy;
      @include inlineFlex(center, center);

      &::after {
        content         : "";
        border-radius   : 7px;
        width           : 12px;
        height          : 12px;
        display         : block;
        background-color: white;
      }
    }
  }
}