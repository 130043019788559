@import "Core/styles/index.scss";

.container {
  text-align      : center;
  background-color: white;
  @include responsive(padding-top, 130px, 93px, 85px);

  .content {
    @include content(1022px, 750px);

    .icon {
      @include responsive(margin-bottom, 25px, 20px, 20px);
      @include responsive(height, 60px, 40px, 40px);
      @include responsive(width, 60px, 40px, 40px);
    }

    h1 {
      font-weight: bold;
      @include responsive(margin-bottom, 20px, 15px, 10px);
      @include responsive(line-height, 1.11, 1.17, 1.25);
      @include responsive(font-size, 56px, 36px, 24px);
      @include content(600px, 550px, 100%);
    }

    h2 {
      font-weight: normal;
      color      : $color-tundora;
      @include responsive(margin-bottom, 30px, 15px, 15px);
      @include responsive(font-size, 18px, 16px, 14px);
    }

    .buttons {
      @include responsive(margin-bottom, 20px, 20px, 15px);

      button {
        @include responsive(width, null, null, 40%);
      }
    }

    h3 {
      font-weight: normal;
      color      : $color-tundora;
      @include responsive(font-size, 18px, 18px, 16px);

      span {
        font-weight: bold;
        color      : $color-downy;
      }
    }
  }
}