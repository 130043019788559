@import "Core/styles/index.scss";

.container {
  border-radius: 5px;
  font-weight  : bold;
  cursor       : pointer;
  position     : relative;
  border       : 2px solid;
  @include responsive(padding, 8px 40px, 6px 30px, 6px 20px);
  @include responsive(font-size, 16px, 16px, 14px);

  .icon {
    margin-right: 10px;
    font-size   : 18px;
  }

  .loading {
    font-size       : 30px;
    background-color: $color-mischka;
    @include flex(center, center);
    @include absolute(0, 0, 0, 0);
  }

  &.text {
    @include button(transparent, transparent, $color-codGray);
  }

  &.primary {
    @include button($color-downy, $color-downy, white);
  }

  &.warning {
    @include button($color-orange, $color-downy, white);
  }

  &.danger {
    @include button($color-wildWatermelon, $color-downy, white);
  }

  &.primaryOutline {
    @include button(transparent, $color-downy, $color-downy);
  }

  &.outline {
    @include button(transparent, $color-mischka, $color-codGray);
  }

  &.light {
    @include button($color-gray, $color-gray, $color-codGray);
  }

  &.secondary {
    padding      : 8px;
    border-radius: 17px;
    @include button($color-ice, $color-ice, $color-codGray);
  }

  &.link {
    padding        : 0;
    font-weight    : 500;
    text-decoration: underline;
    @include button(transparent, transparent, $color-downy);
  }

  &:disabled {
    cursor          : not-allowed;
    border-color    : $color-mischka;
    background-color: $color-mischka;
    color           : rgba($color-codGray, 0.5);

    &:hover {
      border-color    : $color-mischka;
      background-color: $color-mischka;
      color           : rgba($color-codGray, 0.5);
    }
  }
}