$font-family: 'Heebo',
sans-serif;

$primary-color       : #6ACDA5;
$color-downy         : #6ACDA5;
$color-codGray       : #1E1E1E;
$color-athensGray    : #F7F7F9;
$color-tundora       : #434242;
$color-ice           : #E3F7F0;
$color-iceTwo        : #EFF8F5;
$color-iceBlue       : #FAFFFD;
$color-wildWatermelon: #FF6584;
$color-harp          : #E3F1EB;
$color-mischka       : #D8D9E2;
$color-mischkaLight  : #F0F0F4;
$color-gallery       : #ECECEC;
$color-paleLilac     : #E4E4EC;
$color-gray          : #EDEDED;
$color-orange        : #FF7232;
$color-veryLightPink : #D8D8D8;