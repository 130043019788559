@import "Core/styles/index.scss";

.container {
  height  : 33vh;
  position: relative;
  @include flex(center, center);

  img {
    width          : 100%;
    height         : 100%;
    object-fit     : cover;
    object-position: center;
    filter         : blur(10px);
    -webkit-filter : blur(10px);
    @include absolute(0, 0);
  }

  .filter {
    z-index         : 2;
    background-color: rgba(white, 0.7);
    @include absolute(0, 0, 0, 0);
  }

  .content {
    z-index: 3;
    @include content(580px, 597px, 100%);

    h1 {
      font-weight: bold;
      text-align : center;
      @include responsive(margin-bottom, 20px, 10px, 10px);
      @include responsive(line-height, 1.21, normal, 1.33);
      @include responsive(font-size, 24px, 20px, 18px);
    }

    h2 {
      text-align: center;
      color     : $color-tundora;
      @include responsive(font-weight, 500, normal, normal);
      @include responsive(line-height, 1.41, 1.43, 1.46);
      @include responsive(font-size, 17px, 14px, 13px);
    }
  }
}