@import "Core/styles/index.scss";

.container {
  border-radius   : 20px;
  padding         : 5px 10px;
  background-color: $color-downy;
  transform       : translateX(-50%);
  @include fixed(null, null, 100px, 50%);

  .button {
    font-size: 18px;
    color    : white;
    padding  : 2px 8px;

    svg {
      margin-right: 0;
    }
  }
}