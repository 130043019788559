@import "Core/styles/index.scss";

.container {

  .content {
    padding   : 120px 0;
    text-align: justify;
    @include content(900px, 100%);

    h1 {
      font-size    : 36px;
      margin-bottom: 20px;
      text-align   : center;
    }

    h3 {
      font-size    : 18px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 10px;
    }

    a {
      font-weight    : 500;
      text-decoration: underline;
    }

    .phoneInput {
      width: 100%;
      padding: 12px 16px;
      margin: 15px 0;
      border: 1px solid #ddd;
      border-radius: 8px;
      font-size: 1rem;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease;

      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
      }
    }

    .signUpButton {
      width: 100%;
      padding: 12px 16px;
      background-color: #007bff;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease, box-shadow 0.2s ease;

      &:hover {
        background-color: #0056b3;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      }

      &:focus {
        outline: none;
        background-color: #004085;
        box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
      }

      &:active {
        background-color: #003366;
      }

      &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
        box-shadow: none;
      }
    }

  }
}
