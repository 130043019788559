@import "Core/styles/index.scss";

.container {

  .content {
    @include content(580px);

    section {
      padding         : 20px;
      border-radius   : 15px;
      margin-bottom   : 10px;
      cursor          : pointer;
      transition      : all .2s;
      background-color: white;
      position        : relative;
      border          : solid 1px rgba($color-mischka, 0.5);

      .progress {
        font-size: 100px;
      }

      &:hover {
        border-color: $color-downy;
      }

      h4 {
        font-weight  : 600;
        font-size    : 16px;
        margin-bottom: 10px;
        display      : block;
      }

      p {
        font-size  : 14px;
        line-height: 1.14;
        color      : $color-tundora;
      }

      .icon {
        transform: translateY(-50%);
        @include absolute(50%, 20px);
      }
    }
  }
}