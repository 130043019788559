@import "Core/styles/index.scss";

.container {

  header {
    transition   : all .2s;
    border-bottom: 1px solid white;

    section {
      opacity   : 0;
      transition: all .2s;
    }
  }

  &.scrolled {

    header {
      border-color: $color-downy;
    }
  }

  &.visible {

    header {

      section {
        opacity: 1;
      }
    }
  }

  .left {

    img {
      @include responsive(display, block, block, none);
    }
  }

  .center {

    img {
      @include responsive(display, none, none, block);
    }

    >nav {
      @include responsive(display, null, null, none);
    }
  }

  .right {
    @include responsive(display, null, null, none)
  }
}