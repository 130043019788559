@import "Core/styles/index.scss";

.container {
  cursor       : pointer;
  position     : relative;
  border-bottom: 1px solid rgba($color-mischka, 0.44);
  @include responsive(padding, 20px, 10px, 10px);
  @include flex(center);

  &.active {

    &::before {
      content         : "";
      width           : 8px;
      height          : 8px;
      border-radius   : 4px;
      display         : block;
      transform       : translateY(-50%);
      background-color: $color-wildWatermelon;
      @include absolute(50%, null, null, 5px);
    }
  }

  img {
    flex-shrink  : 0;
    border-radius: 5px;
    @include responsive(margin-right, 15px, 10px, 10px);
    @include responsive(height, 70px, 88px, 80px);
    @include responsive(width, 70px, 70px, 60px);
  }

  .content {
    padding-right: 20px;

    h3 {
      font-weight: 600;
      @include responsive(margin-bottom, 5px, 5px, 3px);
      @include responsive(font-size, 16px, 15px, 14px);
    }

    p {
      color: $color-tundora;
      @include responsive(line-height, normal, normal, 1.23);
      @include responsive(margin-bottom, 5px, 5px, 3px);
      @include responsive(font-size, 14px, 13px, 13px);
    }
  }

  svg {
    margin-left: auto;
  }

  &:hover {

    svg {
      color: $color-downy;
    }
  }
}