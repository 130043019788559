@import "Core/styles/index.scss";

.container {
  background-color: $color-athensGray;

  .content {
    display    : flex;
    align-items: center;
    @include content(1440px);
    @include responsive(flex-direction, null, null, column);

    .left {
      overflow: hidden;
      position: relative;
      @include responsive(width, 50%, 50%, 100%);

      .overlay {
        z-index: 2;
        background-image:
          linear-gradient(to bottom, rgba($color-athensGray, 0.96), rgba($color-athensGray, 0) 50%, rgba($color-athensGray, 0.96)),
          linear-gradient(to left, rgba($color-athensGray, 0.96), rgba($color-athensGray, 0) 20%, rgba($color-athensGray, 0) 80%, rgba($color-athensGray, 0.96));
        @include absolute(0, 0, 0, 0);
      }

      img {
        margin-top   : -40px;
        margin-bottom: -60px;
        position     : relative;
        @include responsive(margin-left, -80px, -80px, 0);
      }
    }

    .right {
      @include responsive(width, 50%, 50%, 100%);

      h4 {
        font-weight: bold;
        @include responsive(text-align, null, null, center);
        @include responsive(margin-bottom, 20px, 10px, 5px);
        @include responsive(padding-top, null, null, 20px);
        @include responsive(max-width, 480px, 368px, 100%);
        @include responsive(font-size, 28px, 22px, 16px);
      }

      p {
        @include responsive(padding-bottom, null, null, 20px);
        @include responsive(text-align, null, null, center);
        @include responsive(max-width, 480px, 368px, 100%);
        @include responsive(font-size, 20px, 16px, 14px);
      }
    }
  }
}