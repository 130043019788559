@import "Core/styles/index.scss";

.container {
  padding-bottom  : 20px;
  background-color: white;

  .content {
    @include content(780px, 597px, 100%);

    h4 {
      font-weight: bold;
      text-align : center;
      @include responsive(margin-bottom, 20px, 10px, 5px);
      @include responsive(font-size, 36px, 28px, 20px);
    }

    p {
      text-align: center;
      color     : $color-tundora;
      @include responsive(margin-bottom, 30px, 20px, 15px);
      @include responsive(font-size, 18px, 16px, 14px);
      @include responsive(padding, 0 40px, 0, 0 10px);
    }

    .card {
      cursor          : pointer;
      background-color: white;
      position        : relative;
      border          : solid 2px $color-paleLilac;
      @include responsive(padding, 20px 100px 20px 20px, 20px 100px 20px 20px, 15px 50px 15px 15px);
      @include responsive(margin-bottom, 15px, 15px, 10px);
      @include responsive(border-radius, 20px, 20px, 16px);

      &:hover {
        border-color: $color-downy;
      }

      label {
        margin-bottom: 5px;
        font-weight  : bold;
        @include responsive(font-size, 20px, 20px, 16px);
      }

      summary {
        overflow     : hidden;
        white-space  : nowrap;
        text-overflow: ellipsis;
        @include responsive(font-size, 15px, 13px);
      }

      svg {
        font-size: 16px;
        position : absolute;
        @include responsive(right, 40px, 40px, 20px);
        @include responsive(top, 40px, 40px, 30px);
      }

      &.active {
        summary {
          white-space: normal;
        }

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}