@import "Core/styles/index.scss";

.container {
  z-index         : 10;
  height          : 76px;
  padding         : 13px 0;
  background-color: white;
  border-bottom   : 1px solid $color-downy;
  @include responsive(height, 76px, 60px, 60px);
  @include fixed(0, 0, null, 0);

  .content {
    height: 100%;
    @include content(1200px, 904px, 100%);
    @include flex(center, space-between);

    .left {
      flex: 1;

      img {
        @include responsive(height, null, 15px);
      }

      button {
        padding      : 0;
        width        : 44px;
        height       : 44px;
        font-size    : 18px;
        border-radius: 22px;
        @include inlineFlex(center, center);

        svg {
          margin-right: 0;
        }
      }
    }

    .center {
      flex          : 1;
      font-size     : 16px;
      font-weight   : bold;
      letter-spacing: 0.5px;
      text-align    : center;
    }

    .right {
      flex      : 1;
      text-align: right;

      button {
        @include inlineFlex(center);

        img {
          width         : 30px;
          height        : 30px;
          margin-right  : 10px;
          border-radius : 10px;
          vertical-align: middle;
        }

        .icon {
          font-size  : 14px;
          margin-left: 10px;
        }
      }
    }
  }

  &.light {
    border-color    : transparent;
    background-color: transparent;

    .center {
      color: white;
    }

    &.scrolled {
      background-color: white;
      border-color    : $color-downy;

      .center {
        color: $color-codGray;
      }
    }
  }
}