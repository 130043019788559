@import "Core/styles/index.scss";

.container {
  padding         : 4px;
  border-radius   : 15px;
  background-color: white;
  border          : solid 1px rgba($color-mischka, 0.5);

  .content {
    display: flex;

    .item {
      flex         : 1;
      font-weight  : 500;
      border-radius: 13px;
      text-align   : center;
      cursor       : pointer;
      transition   : all .2s;
      @include responsive(font-size, 16px, 14px, 14px);
      @include responsive(padding, 10px, 8px, 8px);

      &.active {
        color           : white;
        background-color: $color-downy;
      }
    }
  }
}