@import "Core/styles/index.scss";

.container {
  background-color: white;

  .content {
    display    : flex;
    align-items: center;
    @include responsive(flex-direction, null, null, column-reverse);
    @include content(1440px);

    .left {
      @include responsive(width, 50%, 50%, 100%);

      h4 {
        margin-left: auto;
        font-weight: bold;
        @include responsive(text-align, null, null, center);
        @include responsive(margin-bottom, 20px, 10px, 5px);
        @include responsive(padding-top, null, null, 20px);
        @include responsive(max-width, 480px, 368px, 100%);
        @include responsive(font-size, 28px, 22px, 16px);
      }

      p {
        margin-left: auto;
        @include responsive(padding-bottom, null, null, 20px);
        @include responsive(text-align, null, null, center);
        @include responsive(max-width, 480px, 368px, 100%);
        @include responsive(font-size, 20px, 16px, 14px);
      }
    }

    .right {
      overflow: hidden;
      position: relative;
      @include responsive(width, 50%, 50%, 100%);

      .overlay {
        z-index: 2;
        background-image:
          linear-gradient(to bottom, rgba(white, 0.96), rgba(white, 0) 50%, rgba(white, 0.96)),
          linear-gradient(to left, rgba(white, 0.96), rgba(white, 0) 20%, rgba(white, 0) 80%, rgba(white, 0));
        @include absolute(0, 0, 0, 0);
      }

      img {
        position: relative;
        @include responsive(margin-right, -80px, -80px, 0);
      }
    }
  }
}