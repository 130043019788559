@import "Core/styles/index.scss";

.container {
  @include responsive(padding, 200px 0 60px 0, 200px 0 60px 0, 100px 0 60px 0);

  .content {
    @include content(580px, 597px, 100%);

    h1 {
      font-weight: bold;
      text-align : center;
      @include responsive(letter-spacing, 0.21px, 0.17px, normal);
      @include responsive(line-height, normal, normal, 1.4);
      @include responsive(margin-bottom, 10px, 10px, 5px);
      @include responsive(font-size, 24px, 20px, 20px);
    }

    h2 {
      text-align : center;
      font-weight: normal;
      @include responsive(margin-bottom, 30px, 20px, 15px);
      @include responsive(line-height, 1.33, 1.43, 1.43);
      @include responsive(font-size, 15px, 14px, 14px);
    }

    .form {
      margin    : auto;
      text-align: center;

      .type {
        @include responsive(margin-bottom, 30px, 20px);
      }

      button {
        @include responsive(width, 220px, 180px, 185px);
      }
    }
  }
}