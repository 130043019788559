@import "Core/styles/index.scss";

.container {

  .content {
    @include content(400px, 300px, 100%);

    .buttons {
      gap       : 10px;
      margin-top: 20px;
      @include flex(center);

      button {
        flex: 1;
      }
    }
  }
}