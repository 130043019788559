.container {

  h2 {
    font-size     : 24px;
    font-weight   : bold;
    margin-bottom : 34px;
    text-align    : center;
    letter-spacing: 0.21px;
  }

  h3 {
    font-size     : 24px;
    font-weight   : bold;
    line-height   : 1.33;
    margin-bottom : 15px;
    letter-spacing: 0.21px;
  }

  p {
    font-size  : 15px;
    line-height: 1.47;
  }
}