@import "Core/styles/index.scss";

.container {
  padding-top: 106px;

  .content {
    @include content(350px);

    form {
      text-align: center;

      button {
        width: 220px;
      }
    }
  }
}