@import "./index.scss";

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
svg {
  margin        : 0;
  padding       : 0;
  border        : 0;
  font-size     : 100%;
  transition    : all .2s;
  font-family   : inherit;
  vertical-align: baseline;
  color         : $color-codGray;
}

body {
  line-height          : normal;
  font-feature-settings: "tnum";
  font-variant         : tabular-nums;
  font-family          : $font-family;
  color                : $color-codGray;
  background-color     : $color-athensGray;

  * {
    box-sizing: border-box;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }
}

input,
textarea {
  outline    : none;
  caret-color: inherit;
  font-family: inherit;
}

button {
  outline         : none;
  cursor          : pointer;
  background-color: transparent;
}

a {
  text-decoration: none;
  cursor         : pointer;
  color          : $color-codGray;

  &:hover {
    color: $color-downy;
  }
}

img {
  max-width     : 100%;
  vertical-align: middle;
  border-style  : none;
}