@import "Core/styles/index.scss";

.container {

  .content {
    z-index         : 3;
    margin-top      : -20px;
    text-align      : center;
    position        : relative;
    background-color: $color-athensGray;
    box-shadow      : rgba($color-tundora, 0.1) 0px -10px 10px 0px;
    @include responsive(border-radius, 30px 30px 0 0, 30px 30px 0 0, 15px 15px 0 0);
    @include responsive(padding, 30px, 25px, 15px);
  }
}