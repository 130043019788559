@import "Core/styles/index.scss";

.container {
  padding-top     : 213px;
  text-align      : center;
  position        : relative;
  background-color: $color-downy;

  .filter {
    pointer-events: none;
    @include absolute(0, 0, 0, 0);

    img {
      opacity   : 0.12;
      width     : 100%;
      height    : 100%;
      object-fit: cover;
    }
  }

  .content {
    z-index         : 3;
    padding-bottom  : 48px;
    position        : relative;
    border-radius   : 30px 30px 0 0;
    background-color: $color-athensGray;

    .avatar {
      margin-bottom   : 20px;
      width           : 180px;
      height          : 180px;
      object-fit      : cover;
      border-radius   : 100px;
      object-position : center;
      margin-top      : -90px;
      position        : relative;
      background-color: $color-downy;
      border          : 4px solid white;
    }

    h4 {
      font-size     : 24px;
      font-weight   : bold;
      margin-bottom : 10px;
      letter-spacing: 0.21px;
    }

    h5 {
      font-weight: normal;
      font-size  : 15px;
      line-height: 1.33;
    }
  }
}