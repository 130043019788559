@import "Core/styles/index.scss";

.container {
  background-color: white;
  @include responsive(padding, 40px 0, 30px 0);

  .content {
    display         : flex;
    overflow        : hidden;
    position        : relative;
    background-color: $color-iceTwo;
    @include responsive(border-radius, 80px, 60px, 60px);
    @include responsive(padding, 30px, 25px, 20px);
    @include content(1180px, 904px, 100%);

    .image {
      @include responsive(position, relative, relative, absolute);
      @include responsive(margin-bottom, -120px, -120px, 0);
      @include responsive(width, 320px, 227px, 140px);
      @include responsive(bottom, null, null, -60px);

      &:first-child {
        z-index: 2;
        @include responsive(right, null, null, 45%);
      }

      &:last-child {
        @include responsive(left, null, null, 45%);
      }
    }

    .innerContent {
      margin: auto;
      @include responsive(max-width, 381px, 292px, 100%);

      h4 {
        font-weight: bold;
        text-align : center;
        @include responsive(margin-bottom, 15px, 10px, 5px);
        @include responsive(font-size, 36px, 28px, 20px);
      }

      p {
        text-align: center;
        color     : $color-tundora;
        @include responsive(margin-bottom, 30px, 20px, 15px);
        @include responsive(font-size, 18px, 16px, 14px);
      }

      .buttons {
        @include responsive(padding-bottom, null, null, 230px);
        @include responsive(text-align, null, null, center);

        .appStore {
          @include responsive(margin-right, 20px, 16px, 12px);
          @include responsive(height, 57px, 40px, 33px);
        }

        .googlePlay {
          @include responsive(height, 57px, 40px, 33px);
        }
      }
    }
  }
}