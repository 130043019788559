@import "Core/styles/index.scss";

.container {
  z-index         : 2;
  background-color: $color-mischkaLight;
  border-bottom   : 1px solid $color-downy;
  @include responsive(top, 76px, 60px);
  @include fixed(null, 0, null, 0);

  .content {
    @include content(580px, 597px);
    @include flex(center);

    label {
      line-height: 1;
      opacity    : 0.8;
      font-weight: 500;
      font-size  : 14px;
    }

    .tags {
      overflow-x : auto;
      padding    : 10px 0;
      white-space: nowrap;

      span {
        font-size       : 14px;
        border-radius   : 15px;
        margin          : 0 5px;
        white-space     : nowrap;
        background-color: white;
        padding         : 5px 10px;
        border          : solid 2px $color-mischka;
        @include inlineFlex(center);

        button {
          padding      : 0;
          margin-left  : 5px;
          border-radius: 7px;
          width        : 14px;
          height       : 14px;
          @include inlineFlex(center, center);

          svg {
            margin-right: 0;
            font-size   : 6px;
          }
        }
      }
    }
  }
}