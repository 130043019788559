@import "Core/styles/index.scss";

.container {
  z-index: 9999;
  @include flex(null, null, column);
  @include fixed(0, 0, 0, 0);

  .content {
    flex            : 1;
    max-height      : 100%;
    padding-top     : 84px;
    padding-bottom  : 70px;
    background-color: black;
    @include flex(center, center);

    .cropper {
      max-width : 100%;
      max-height: 100%;
    }
  }
}