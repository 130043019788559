@import "Core/styles/index.scss";

.container {

  .content {
    @include responsive(padding-top, null, null, 20px);
    @include content(300px, 300px, 100%);

    button {
      width        : 100%;
      margin-bottom: 20px;
    }
  }
}