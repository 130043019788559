@import "Core/styles/index.scss";

.container {
  text-align: center;
  padding   : 116px 0 30px 0;

  .content {
    @include flex(center, null, column);

    img {
      width        : 180px;
      height       : 180px;
      border-radius: 100px;
      border       : 4px solid $color-downy;
    }

    button {
      font-weight  : 500;
      font-size    : 14px;
      line-height  : 1.67;
      border-radius: 16px;
      margin-top   : -16px;
      padding      : 4px 15px;
      position     : relative;
    }
  }
}