@import "Core/styles/index.scss";

.container {
  text-align: center;
  @include responsive(margin-bottom, 30px, 30px, 20px);

  .content {
    font-weight     : 500;
    border-radius   : 15px;
    cursor          : pointer;
    transition      : all .2s;
    background-color: white;
    padding         : 8px 12px;
    display         : inline-block;
    border          : 2px solid $color-mischka;
    @include responsive(font-size, 14px, 14px, 13px);
    @include responsive(margin, 5px, 5px, 4px);

    span {
      margin-left: 5px;
    }

    &.active {
      color           : white;
      border-color    : $color-downy;
      background-color: $color-downy;
    }
  }
}