@import "Core/styles/index.scss";

.container {
  position: relative;
  display : inline-block;
  @include responsive(padding, 20px 10px, 20px 10px, 20px 0);
  @include responsive(width, calc(100% / 3), calc(100% / 3), 100%);

  label {
    border-radius   : 8px;
    font-weight     : bold;
    padding         : 4px 8px;
    color           : white;
    background-color: $color-wildWatermelon;
    @include responsive(line-height, normal, normal, 1);
    @include responsive(font-size, 16px, 14px, 14px);
    @include absolute(10px, null, null, 10px);
  }

  img {
    width          : 100%;
    border-radius  : 17px;
    object-fit     : cover;
    object-position: center;
    @include responsive(height, 236px, 181px, 180px);
  }

  .content {
    border-radius   : 17px;
    transition      : all .2s;
    background-color: white;
    position        : relative;

    &:hover {
      box-shadow: rgba($color-tundora, 0.1) 0px 8px 24px;
    }

    .innerContent {
      padding: 16px 16px 35px 16px;

      h4 {
        font-weight: bold;
        @include responsive(margin-bottom, 2px, 5px, 5px);
        @include responsive(font-size, 20px, 18px, 16px);
      }

      p {
        font-size: 14px;
        overflow : hidden;
        color    : $color-tundora;
        @include responsive(line-height, 1.29, 1.43, 1.43);
        @include responsive(height, 54px, 80px, 80px);
      }
    }

    button {
      white-space: nowrap;
      transform  : translateX(-50%);
      @include absolute(null, null, -20px, 50%);

      svg {
        @include responsive(font-size, 16px, 14px, 12px);
        margin-left: 5px;
      }
    }
  }
}