@import "Core/styles/index.scss";

.container {
  display      : flex;
  cursor       : pointer;
  position     : relative;
  padding      : 15px 40px 15px 0;
  border-bottom: 1px solid rgba($color-mischka, 0.44);

  .icon {
    width           : 60px;
    height          : 60px;
    font-size       : 30px;
    margin-right    : 15px;
    border-radius   : 20px;
    background-color: $color-harp;
    color           : $color-downy;
    @include flex(center, center);

    img {
      width        : 100%;
      height       : 100%;
      border-radius: 20px;
    }
  }

  .content {
    flex       : 1;
    padding-top: 5px;

    h3 {
      font-weight  : 600;
      margin-bottom: 5px;
      @include responsive(font-size, 16px, 15px);
    }

    p {
      max-height: 23px;
      overflow  : hidden;
      color     : $color-tundora;
      @include responsive(font-size, 14px, 13px);
    }

    section {
      @include flex(center);

      .bookmark {
        padding    : 8px;
        margin-left: 8px;
      }
    }

    button {
      display   : none;
      margin-top: 10px;
    }
  }

  .arrow {
    @include absolute(36px, 20px);
  }

  &.active {

    .content {

      p {
        max-height: unset;
      }

      button {
        display: block;
      }
    }

    .arrow {
      transform: rotate(180deg);
    }
  }
}