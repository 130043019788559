@import "Core/styles/index.scss";

.container {
  @include flex(center, center);

  .content {
    text-align: center;
    @include content(580px, 597px, 100%);

    img {
      @include responsive(margin-bottom, 30px, 20px, 20px);
      @include responsive(width, 381px, 305px, 100%);
    }

    h1 {
      font-weight   : bold;
      letter-spacing: 0.21px;
      @include responsive(margin-bottom, 15px, 10px, 10px);
      @include responsive(font-size, 24px, 20px, 20px);
    }

    p {
      @include responsive(margin-bottom, 30px, 20px, 20px);
      @include responsive(line-height, 1.33, 1.43, 1.43);
      @include responsive(font-size, 15px, 14px, 14px);
    }
  }
}