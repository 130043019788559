@import "Core/styles/index.scss";

.container {
  z-index         : 3;
  padding         : 4px 0;
  background-color: white;
  @include responsive(top, 76px, 60px, 60px);
  @include fixed(null, 0, null, 0);

  .content {
    @include content(580px);
  }
}