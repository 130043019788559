@import "Core/styles/index.scss";

.container {

  &.content {
    z-index: 11000;

    .innerContent {
      border-radius: 20px;
      text-align   : center;
      max-width    : calc(100% - 40px);
      @include responsive(padding, 45px, 20px);

      h3 {
        font-size    : 24px;
        font-weight  : bold;
        margin-bottom: 15px;
        line-height  : normal;
      }

      p {
        font-size    : 18px;
        margin-bottom: 25px;
        line-height  : normal;
      }

      button {
        width        : 100%;
        margin-bottom: 10px;
        display      : block;
      }
    }
  }
}