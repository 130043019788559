@import "Core/styles/index.scss";

.container {

  .content {

    form {
      text-align: center;
      @include content(350px);

      p {
        margin-bottom: 20px;
      }

      .password {
        position: relative;

        button {
          font-weight: 500;
          font-size  : 12px;
          line-height: 1.67;
          color      : $color-codGray;
          transform  : translateY(-50%);
          @include absolute(50%, 15px);
        }
      }

      .row {
        gap    : 10px;
        display: flex;
      }

      button[type=submit] {
        margin-top: 20px;
        width     : 220px;
      }
    }
  }
}