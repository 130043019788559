@import "Core/styles/index.scss";

.container {
  padding-top: 105px;

  .content {
    @include content(580px);

    p {
      font-size    : 15px;
      line-height  : 1.33;
      margin-bottom: 30px;
      text-align   : center;
    }
  }
}