@import "Core/styles/index.scss";

.container {
  fill: transparent;

  .background {
    stroke: $color-athensGray;
  }

  .progress {
    stroke-linecap : round;
    stroke-linejoin: round;
    stroke         : $color-tundora;
  }

  .label {
    font-weight: 500;
    font-size  : .20em;
    fill       : $color-codGray;
  }

  .percent {
    font-weight: 600;
    font-size  : .35em;
    fill       : $color-codGray;
  }

  &.red {

    .progress {
      stroke: $color-wildWatermelon;
    }

    .percent {
      fill: $color-wildWatermelon;
    }
  }

  &.yellow {

    .progress {
      stroke: $color-orange;
    }

    .percent {
      fill: $color-orange;
    }
  }

  &.green {

    .progress {
      stroke: $color-downy;
    }

    .percent {
      fill: $color-downy;
    }
  }
}