@import "Core/styles/index.scss";

.container {
  @include responsive(display, none, none, block);
}

.content {
  z-index         : 20;
  background-color: white;
  @include fixed(60px, 0, 0, 0);

  section {
    padding: 20px;
    display: flex;

    button {
      flex: 1;
    }
  }

  div {

    button {
      margin-top : 20px;
      text-align : left;
      margin-left: 20px;
      padding    : 10px !important;
      width      : calc(100% - 40px);
      @include flex(center);

      img {
        width : 50px;
        height: 50px;
      }

      span {
        font-size  : 18px;
        margin-left: 10px;
        font-weight: normal;
        display    : inline-block;
      }

      svg {
        font-size   : 20px;
        margin-left : auto;
        margin-right: 20px;
        display     : inline-block;
      }
    }
  }

  nav {
    padding: 20px;

    span {
      font-size    : 18px;
      margin-bottom: 30px;
      display      : block;
    }
  }

  .download {
    margin-top: 40px;
    text-align: center;

    h3 {
      font-size    : 18px;
      font-weight  : bold;
      line-height  : 1.33;
      margin-bottom: 10px;
    }

    .buttons {

      .appStore {
        height      : 34px;
        margin-right: 10px;
      }

      .googlePlay {
        height: 34px;
      }
    }
  }
}