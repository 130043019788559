@mixin content($web, $tablet: null, $phone: null) {
  width       : $web;
  margin-left : auto;
  margin-right: auto;
  max-width   : calc(100% - 40px);
  @include responsive(width, $web, $tablet, $phone);
}

@mixin fixed($top: null, $right: null, $bottom: null, $left: null) {
  top     : $top;
  left    : $left;
  right   : $right;
  bottom  : $bottom;
  position: fixed;
}

@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
  top     : $top;
  left    : $left;
  right   : $right;
  bottom  : $bottom;
  position: absolute;
}

@mixin absoluteCenter() {
  top      : 50%;
  left     : 50%;
  position : absolute;
  transform: translateX(-50%) translateY(-50%);
}

@mixin flex($alignItems: null, $justifyContent: null, $direction: null) {
  flex-direction : $direction;
  align-items    : $alignItems;
  justify-content: $justifyContent;
  display        : flex;
}

@mixin inlineFlex($alignItems: null, $justifyContent: null, $direction: null) {
  flex-direction : $direction;
  align-items    : $alignItems;
  justify-content: $justifyContent;
  display        : inline-flex;
}

@mixin button($color, $borderColor, $textColor) {
  background-color: $color;
  color           : $textColor;
  border-color    : $borderColor;

  &:hover {
    background-color: lighten($color, 5);
    color           : lighten($textColor, 5);
    border-color    : lighten($borderColor, 5);
  }

  &:active {
    background-color: darken($color, 5);
    color           : darken($textColor, 5);
    border-color    : darken($borderColor, 5);
  }
}

@mixin phone {
  @media screen and (max-width: 499px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 500px) and (max-width: 1030px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 1030px) {
    @content;
  }
}

@mixin responsive($prop, $web, $tablet: null, $phone: null) {
  #{$prop}: $web;

  @if $tablet !=null {
    @if $phone !=null {
      @include tablet {
        #{$prop}: $tablet;
      }
    }

    @else {
      @include mobile {
        #{$prop}: $tablet;
      }
    }
  }

  @if $phone !=null {
    @include phone {
      #{$prop}: $phone;
    }
  }
}