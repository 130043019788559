@import "Core/styles/index.scss";

.container {
  @include responsive(margin-bottom, 20px, 20px, 15px);

  .content {
    display      : flex;
    border-bottom: 1px solid $color-mischka;
    @include responsive(padding, 0 15px 15px 15px, 0 15px 15px 15px, 0 0 10px 0);
    @include content(620px, 100%, 100%);

    section {
      min-width: 0;
      @include responsive(margin-right, 10px, 10px, 5px);
      @include flex(center);

      span {
        flex-shrink     : 0;
        font-weight     : bold;
        border-radius   : 16px;
        color           : $color-codGray;
        background-color: $color-veryLightPink;
        @include responsive(font-size, 16px, 16px, 14px);
        @include responsive(height, 32px, 32px, 24px);
        @include responsive(width, 32px, 32px, 24px);
        @include inlineFlex(center, center);
      }

      label {
        font-weight  : 500;
        display      : none;
        text-align   : left;
        overflow     : hidden;
        white-space  : nowrap;
        text-overflow: ellipsis;
        @include responsive(margin-left, 10px, 10px, 5px);
        @include responsive(font-size, 16px, 16px, 13px);
      }

      &.active {
        flex: 1;

        span {
          color           : white;
          background-color: $color-downy;
        }

        label {
          display: block;
        }
      }

      &.inactive {

        span {
          background-color: $color-mischka;
        }

        label {
          display: none;
        }
      }
    }
  }
}