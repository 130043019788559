@import "Core/styles/index.scss";

.container {

  header {
    transition   : all .2s;
    border-bottom: 1px solid white;
  }

  &.scrolled {

    header {
      border-color: $color-downy;
    }
  }
}