@import "Core/styles/index.scss";

.container {
  padding         : 30px;
  border-radius   : 15px;
  background-color: white;
  border          : solid 1px rgba($color-mischka, 0.5);

  h4 {
    font-weight  : 500;
    line-height  : 1.1;
    font-size    : 20px;
    margin-bottom: 20px;
  }
}