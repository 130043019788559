@import "Core/styles/index.scss";

.container {
  margin-bottom: 10px;
  position     : relative;

  label {
    font-weight: 500;
    font-size  : 14px;
    @include absolute(10px, null, null, 15px);
  }

  input {
    font-weight  : 500;
    width        : 100%;
    font-size    : 16px;
    border-radius: 15px;
    display      : block;
    transition   : all .2s;
    padding      : 36px 15px 10px 15px;
    border       : 2px solid rgba($color-mischka, 0.5);

    &:hover {
      border-color: rgba($color-downy, 0.5);
    }

    &.filled,
    &:focus {
      border-color: $color-downy;
    }

    &:disabled {
      background-color: white;
    }
  }

  ::placeholder {
    color: rgba($color-codGray, 0.44);
  }

  span {
    margin-top  : 5px;
    font-weight : 500;
    font-size   : 14px;
    text-align  : left;
    padding-left: 10px;
    display     : block;
    color       : $color-wildWatermelon;
  }

  &.error {

    input {
      border-color: $color-wildWatermelon;
    }
  }
}