@import "Core/styles/index.scss";

.container {
  display         : flex;
  z-index         : 10000;
  padding         : 20px 30px;
  background-color: white;
  @include fixed(null, 0, 0, 0);

  button {
    font-size: 17px;
  }

  .buttons {
    margin-left: auto;
  }

  .done {
    margin-left: 30px;
  }
}