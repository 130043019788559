@import "Core/styles/index.scss";

.container {
  padding         : 4px;
  display         : flex;
  border-radius   : 18px;
  background-color: $color-athensGray;

  button {
    border-radius: 18px;
    font-weight  : normal;
    white-space  : nowrap;
    @include responsive(padding, 8px 16px, 8px 10px, 8px 10px);
    @include responsive(font-size, 16px, 14px, 14px);
    @include responsive(width, 150px, 130px, 100%);

    svg {
      @include responsive(margin-right, 10px, 5px, 5px);
    }
  }

  @include mobile {
    border-radius: 0;
    z-index      : 10;
    border-top   : 1px solid $color-downy;
    @include fixed(null, 0, 0, 0);

    a {
      width: calc(100% / 3);

      button {
        @include flex(center, center, column);

        &.active {
          border-color    : transparent;
          background-color: transparent;
          color           : $color-downy;
        }
      }
    }
  }
}