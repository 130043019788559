@import "Core/styles/index.scss";

.container {

  .close {
    padding      : 0;
    width        : 44px;
    height       : 44px;
    margin-bottom: 30px;
    border-radius: 22px;

    svg {
      margin-right: 0;
    }
  }

  img {
    width        : 100%;
    border-radius: 14px;
    margin-bottom: 20px;
  }

  h3 {
    font-weight: bold;
    @include responsive(letter-spacing, 0.21px, normal, normal);
    @include responsive(margin-bottom, 15px, 10px, 15px);
    @include responsive(line-height, 1.33, 1.33, 1.35);
    @include responsive(font-size, 24px, 18px, 20px);
  }

  p {
    @include responsive(line-height, 1.47, 1.57, 1.43);
    @include responsive(font-size, 15px, 14px, 14px);
  }
}