@import "Core/styles/index.scss";

.container {
  z-index         : 20;
  background-color: rgba(white, 0.5);
  @include flex(center, center);
  @include absolute(0, 0, 0, 0);

  .content {
    width : 100px;
    height: 100px;
  }
}