@import "Core/styles/index.scss";

.container {
  padding: 105px 0 60px 0;

  .content {
    @include content(580px);

    p {
      font-size    : 15px;
      line-height  : 1.33;
      margin-bottom: 30px;
      text-align   : center;
    }

    section {
      margin-bottom: 15px;
    }

    .button {
      text-align: center;

      button {
        width: 200px;
      }
    }
  }
}