@import "Core/styles/index.scss";

.container {
  display      : flex;
  margin-bottom: 15px;

  .icon {
    border-radius : 4px;
    width         : 28px;
    height        : 28px;
    margin-right  : 10px;
    vertical-align: middle;
    cursor        : pointer;
    color         : transparent;
    display       : inline-block;
    border        : 2px solid $color-mischka;
  }

  span {
    padding-top: 5px;
    font-size  : 14px;
  }

  &.checked {

    .icon {
      color           : white;
      border-color    : $color-downy;
      background-color: $color-downy;
      @include inlineFlex(center, center);
    }
  }
}